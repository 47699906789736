class Appointment {
    /**
     * 
     * @param Object obj 
     */
    constructor(obj) {
        this.creationDate = obj.creationDate; // string(Y-m-d h:i:s)
        this.status = obj.status; // string
        this.text = obj.text; // string
        this.dateStart = obj.dateStart; // string(Y-m-d h:i:s)
        this.dateEnd = obj.dateEnd; // string(Y-m-d h:i:s)
        this.type = obj.type; // string
        this.links = obj.links ?? []; // link[]
    }
}

export default Appointment;
