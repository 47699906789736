<template>
  <div v-if="isCurrent" class="row amp-step amp-step-container text-start">
    <div class="row">
      <div class="col-2 relative"><span class="content-status-dot">{{ step.index }}/{{ nbTotalSteps }}</span></div>
      <div class="col-10 pt-3 pb-2" style="padding-left: 20px">
        <h6 class="avproj-step-status mt-0"><strong>ÉTAPE EN COURS</strong></h6>
        <h5 class="m-0"><strong>{{ step.name }}</strong></h5>
        <span class="date" v-html="step.text"></span>
      </div>
    </div>
  </div>
  <div v-else class="row amp-step steps-avc-suivi text-start">
    <div class="row" style="position: relative">
      <div :class="'col-2 relative border-progress' + (isLast ? ' border-progress-end' : '')"></div>
      <div class="col-8 px-4 py-2">
        <h6 class="suivi-step-status"><strong>ÉTAPE {{ step.index }}</strong></h6>
        <h5 class="m-0"><strong>{{ step.name }}</strong></h5>
        <span class="date mt-2 mb-2" v-html="step.text"></span>
        <div class="separator"/>
      </div>
      <div class="col-2 mt-3">
        <img src="@/assets/images/dot-interro.svg" class="picto-size" :alt="step.hint"
             @mouseover="handleHintModalDisplay(false)" @mouseout="handleHintModalDisplay(true)">
      </div>
      <HintModal :isHintModalHidden="isHintModalHidden" :text="step.hint" @mouseover="handleHintModalDisplay(false)" @mouseout="handleHintModalDisplay(true)"/>
    </div>
  </div>
</template>

<script>
import HintModal from '../../components/Common/HintModal.vue';
import TimelineStep from '../../model/TimelineStep';

export default {
  components: {
    HintModal
  },
  data() {
    return {
      isHintModalHidden: true,
    }
  },
  methods: {
    /**
     * Hide/show the hint modal
     * @param {Boolean} hidden
     */
    handleHintModalDisplay(hidden) {
      this.isHintModalHidden = hidden;
    }
  },
  props: {
    step: TimelineStep,
    isCurrent: Boolean,
    isLast: Boolean,
    nbTotalSteps: Number
  }
}
</script>

<style scoped lang="scss">
.picto-size {
  width: 20px;
  cursor: pointer;
}

.steps-avc-suivi .suivi-step-status {
  font-size: 13px;
}

.steps-avc-suivi h5, .steps-avc-suivi .date {
  font-size: 12px;
}

.steps-avc-suivi .date {
  line-height: 100%;
  display: block;
}

.avproj-step-status {
  margin: 0;
  margin-top: 20px;
}

.suivi-step-status {
  margin: 0;
}

.amp-step .separator {
  border-bottom: 1px #396FFB33 solid;
}
</style>

<style lang="scss">
.amp-step-container span a {
  color: #F9EE16;
}
</style>
